<template>
  <div class="box">
    <div class="titles">
      <h2>{{ title }}</h2>
      <i
        class="el-icon-error"
        @click="delForm"
        v-if="title !== '网格配置1'"
      ></i>
    </div>
    <ax-form ref="formBox" :formBuilder="formBuilder">
      <template slot="gridId">
        <Cascader ref="cascader" :list="list" :placeholder="'请选择责任网格'" />
      </template>
    </ax-form>
  </div>
</template>

<script>
import api from "../api";
import Cascader from "@/pages/views/Acomponents/cascader";
const options = [
  { label: "网格长", value: "0" },
  { label: "网格员", value: "1" },
];
const formList = [
  {
    label: "责任网格",
    type: "",
    model: "gridId",
    options: {},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "责任网格" }],
  },
  {
    label: "网格角色",
    type: "radio",
    model: "gridRole",
    options: { options },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "网格角色" }],
  },
];
export default {
  components: { Cascader },
  props: ["title"],
  data() {
    return {
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
      obj: {},
      list: [],
    };
  },

  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    delForm() {
      this.$emit("delForm");
    },
    // 详情
    async getGridInfo(id) {
      const res = await api.gridDetail(id);
      this.$refs.cascader.resetForm()
      this.$refs.cascader.showLabel({
        value: res.data.gridConfigList[0].gridId,
        list: this.list,
      });
      this.$refs.formBox.setFieldsValue(res.data.gridConfigList[0]);
    },
    save() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        let gridId = this.$refs.cascader.getSelectValue();
        this.$emit("addForms", {
          gridId: gridId.value,
          gridName: gridId.label,
          ...value,
        });
      });
    },

    resetForm() {
      this.$refs.formBox.resetFields();
    },
    seleteMsg(arr) {
      this.list = arr;
      this.$refs.cascader.resetForm()
    },
  },

  created() {},

  mounted() {},
};
</script>
<style lang='less' scoped>
.box {
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #556bff;
  margin-bottom: 2%;
  padding: 2%;
}

.titles {
  width: 100%;
  display: flex;
  justify-content: space-between;

  i {
    font-size: 0.08rem;
  }
}

/deep/.ant-btn-primary {
  background-color: rgb(230, 162, 60) !important;
  border: 0px solid #000;
}
</style>